import React, { useEffect, useState, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Layout, SEO } from "../../components/structure";
import styled, { css } from "styled-components";
import { mq, brand, font } from "../../styles";
import axios from "axios";
import { Content, Hero, Quote, Button } from "../../components/blocks";
import english from "../../../content/translations/en-CA/careers.json";
import french from "../../../content/translations/fr-CA/careers.json";
import { filter } from "../../../content/menu";

const CareersCurrentOpeningsPage = () => {
  let lang = english;

  const pageQuery = useStaticQuery(graphql`
    query {
      heroImg: file(
        base: { eq: "Plusgrade-Hero-Banner-Current-Openings.jpg" }
      ) {
        ...HeroImage
      }
    }
  `);

  const WarningMessage = styled.div`
    color: ${brand.colors.pg_primary_dark};
    max-width: 1020px;
    padding-top: 50px;
    margin: auto;
    font-weight: 600;
    font-style: italic;
  `;

  const RolesGrid = styled.div`
    padding: 50px 0 100px 0;
    overflow-y: auto;
    margin-top: 0;
    width: 95%;
    max-width: 1020px;
    margin: auto;

    .filter-controls {
      padding: 10px 0;
      color: ${brand.colors.pg_primary_dark};
      select {
        padding: 5px;
        border: none;
        margin: 0 10px;
        width: 200px;
      }

      .team-options {
        width: 200px;
      }

      .clear-list-button {
        width: 120px;
        line-height: 16px;
        color: #fff;
        padding: 8px 30px;
        background-color: ${brand.colors.pg_blue};
        border-radius: 26px;
        border: 1px solid #fff;
        font-family: ${font.family.sherika};
        cursor: pointer;

        &:hover {
          background-color: #fff;
          border-color: ${brand.colors.pg_blue};
          color: ${brand.colors.pg_blue};
        }
      }

      @media screen and ${mq.maxMd} {
        span,
        a {
          display: block;
        }

        select {
          margin: 0;
          padding-left: 0;
          padding-right: 10px;
          margin-right: 10px;
          width: 100% !important;

          border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
        }

        a {
          margin-top: 10px;
        }
      }
    }

    .loading-text {
      color: ${brand.colors.pg_primary_dark};
    }
    .no-careers-message {
      color: ${brand.colors.pg_primary_dark};
    }

    #positions {
      color: ${brand.colors.pg_primary_dark};
      tr {
        td {
          a:hover {
            color: ${brand.colors.pg_orange};
          }
        }
      }
    }

    .lever-link {
      color: ${brand.colors.pg_primary_dark};
      a {
        color: ${brand.colors.pg_orange};
      }
    }
  `;

  const [positions, setState] = useState([]);
  const [fullList, setFullData] = useState([]);
  const [currentFilterList, setFilterList] = useState([]);
  const filteredLocationList = useRef([]);
  const filteredDepartmentList = useRef([]);
  const filteredTeamList = useRef([]);

  const [departmentOptions, setDeptDropdownOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const [loading, setLoading] = useState(true);

  const [defaultLocation, setLocationDropdown] = useState("");
  const [defaultDepartment, setDepartmentDropdown] = useState("");
  const [defaultTeam, setTeamDropdown] = useState("");

  const [locDropdownWidth, setLocationWidth] = useState("120px");
  const [deptDropdownWidth, setDeptWidth] = useState("200px");
  const [teamDropdownWidth, setTeamWidth] = useState("100px");

  useEffect(() => {
    axios
      .get(`https://api.lever.co/v0/postings/plusgrade`)
      .then(res => {
        console.log("Data is: " + JSON.stringify(res.data));
        setState(res.data);
        setFullData(res.data);
      })
      .catch(error => {
        setState([]);
        setFullData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const applyLocationFilter = e => {
    //Clear Department and Team Details
    filteredLocationList.current = [];
    setDeptDropdownOptions([]);
    filteredDepartmentList.current = [];
    setDepartmentDropdown("");
    setTeamOptions([]);
    setDeptWidth("200px");
    setTeamWidth("100px");

    //Change default values based on selection
    let value = e.target.value;
    setLocationWidth(setDropdownWidth("location-filter-dropdown"));
    setLocationDropdown(value);

    //Find Positions with selected location
    let tempPositionsByLocation = [];
    for (let x = 0; x < fullList.length; x++) {
      if (fullList[x].categories.location === value) {
        tempPositionsByLocation.push(fullList[x]);
      }
    }

    filteredLocationList.current = [...tempPositionsByLocation];
    setState(tempPositionsByLocation);
    setFilterList(tempPositionsByLocation);
    console.log(
      "FILTERED LOCATION LIST IS: " + JSON.stringify(filteredLocationList)
    );
    //Find and remove duplicates

    if (filteredLocationList.current.length == 0) {
      return;
    } else {
      let tempDepartmentDropdownOptions = [];
      tempDepartmentDropdownOptions.push(
        filteredLocationList.current[0].categories.department
      );
      for (let x = 0; x < filteredLocationList.current.length; x++) {
        if (
          tempDepartmentDropdownOptions.indexOf(
            filteredLocationList.current[x].categories.department
          ) < 0
        ) {
          tempDepartmentDropdownOptions.push(
            filteredLocationList.current[x].categories.department
          );
        }
      }

      //Set Department Dropdown Options
      setDeptDropdownOptions(tempDepartmentDropdownOptions);
    }
  };

  const applyDepartmentFilter = e => {
    filteredDepartmentList.current = [];
    setDepartmentDropdown("");
    setTeamOptions([]);
    filteredTeamList.current = [];
    setTeamWidth("100px");

    let value = e.target.value;
    setDeptWidth(setDropdownWidth("department-filter-dropdown"));
    setDepartmentDropdown(value);

    let tempPositionsByDepartment = [];
    for (let x = 0; x < filteredLocationList.current.length; x++) {
      if (filteredLocationList.current[x].categories.department === value) {
        tempPositionsByDepartment.push(filteredLocationList.current[x]);
      }
    }
    filteredDepartmentList.current = [...tempPositionsByDepartment];

    setState(tempPositionsByDepartment);
    setFilterList(tempPositionsByDepartment);

    let tempTeamDropdownOptions = [];

    tempTeamDropdownOptions.push(
      filteredDepartmentList.current[0].categories.team
    );
    for (let x = 0; x < filteredDepartmentList.current.length; x++) {
      if (
        tempTeamDropdownOptions.indexOf(
          filteredDepartmentList.current[x].categories.team
        ) < 0
      ) {
        tempTeamDropdownOptions.push(
          filteredDepartmentList.current[x].categories.team
        );
      }
    }

    setTeamOptions(tempTeamDropdownOptions);
  };

  const applyTeamFilter = e => {
    let value = e.target.value;
    setTeamWidth(setDropdownWidth("team-filter-dropdown"));
    setTeamDropdown(value);

    let tempPositionsByTeam = [];

    for (let x = 0; x < filteredDepartmentList.current.length; x++) {
      if (filteredDepartmentList.current[x].categories.team === value) {
        tempPositionsByTeam.push(filteredDepartmentList.current[x]);
      }
    }

    setState(tempPositionsByTeam);
    setFilterList(tempPositionsByTeam);
    filteredTeamList.current = [...tempPositionsByTeam];
  };

  const setDropdownWidth = id => {
    let selectedId = document.getElementById(id);
    let selectedText = selectedId.options[selectedId.selectedIndex].text;
    let newWidth = 40 + selectedText.length * 10 + "px";

    return newWidth;
  };

  const clearFilters = () => {
    setState(fullList);
    setFilterList([]);
    setDepartmentDropdown("");
    setTeamDropdown("");
    setLocationWidth("150px");
    setDeptWidth("200px");
    setTeamWidth("100px");
  };

  return (
    <Layout>
      <SEO
        title="Current Openings | Plusgrade"
        description={
          "Looking to revolutionize ancillary revenue? Join our team."
        }
        keywords={"new jobs, careers, openings, job page"}
      />
      <Hero
        title={"Current Openings"}
        image={pageQuery["heroImg"]}
        imageStyle={{ objectPosition: "center center" }}
        fullBG
      />
      <Content whiteBG noMargin>
        <WarningMessage>
          Please be aware of fraudulent job offers – we will never contact you
          from a personal email account (e.g., Gmail). <br />
          Always verify communications come from official company channels such
          as plusgrade.com or points.com.
        </WarningMessage>
        <RolesGrid>
          <div className={"filter-controls"}>
            <span>Filter By: </span>

            <select
              style={{ width: locDropdownWidth }}
              id={"location-filter-dropdown"}
              name="locationFilter"
              onChange={e => applyLocationFilter(e)}
              defaultValue={defaultLocation}
            >
              <option disabled value="">
                Location
              </option>
              <option value="Tel Aviv">Tel Aviv</option>
              <option value="Montreal, Quebec">Montreal</option>
              <option value="Toronto, Ontario">Toronto</option>
            </select>

            {departmentOptions.length > 1 ? (
              <select
                style={{ width: deptDropdownWidth }}
                id={"department-filter-dropdown"}
                name="departmentFilter"
                onChange={e => applyDepartmentFilter(e)}
                defaultValue={defaultDepartment}
              >
                <option disabled value="">
                  Department
                </option>
                {departmentOptions.map((department, index) => (
                  <option key={index} value={department}>
                    {department}
                  </option>
                ))}
              </select>
            ) : (
              <></>
            )}

            {teamOptions.length > 1 ? (
              <select
                style={{ width: teamDropdownWidth }}
                id="team-filter-dropdown"
                name="teamFilter"
                onChange={e => applyTeamFilter(e)}
                defaultValue={defaultTeam}
              >
                <option value="">Team</option>
                {teamOptions.map((team, index) => (
                  <option key={index} value={team}>
                    {team}
                  </option>
                ))}
              </select>
            ) : (
              <></>
            )}

            {currentFilterList.length > 0 ? (
              <span className={"clear-list-button"} onClick={clearFilters}>
                Clear
              </span>
            ) : (
              <></>
            )}
          </div>
          {loading ? (
            <p className="loading-text">
              {lang.current_openings.details.loading}
            </p>
          ) : (
            ""
          )}
          {positions.length > 0 && (
            <table id={"positions"}>
              <thead>
                <tr>
                  <th>{lang.current_openings.details.role_title}</th>
                  <th>{lang.current_openings.details.role_department}</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {positions.map((position, index) =>
                  position.text === "Future Opportunities with Plusgrade" ? (
                    <></>
                  ) : (
                    <PositionRow
                      key={index}
                      position={position}
                      index={index}
                    />
                  )
                )}
              </tbody>
            </table>
          )}
          {positions.length === 0 ? (
            <span className="no-careers-message">No roles found</span>
          ) : (
            ""
          )}

          <p className="lever-link">
            See all positions on{" "}
            <a href="https://jobs.lever.co/plusgrade">Lever</a>
          </p>
        </RolesGrid>
      </Content>
    </Layout>
  );
};

export default CareersCurrentOpeningsPage;

const PositionRow = ({ position, index }) => {
  return (
    <tr>
      <td style={{ width: "50%" }}>
        <a
          className={"link-button"}
          href={position.hostedUrl}
          rel="noopener noreferrer"
        >
          {position.text}
        </a>
      </td>
      <td style={{ width: "30%" }}>{position.categories.department}</td>
      <td style={{ width: "20%" }}>{position.categories.location}</td>
    </tr>
  );
};
